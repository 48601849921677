
window.button_export = false;
window.table = function (options)
{
    let table_id = options.id || null;
    let container = options.container;
    let data_url = options.data_url;
    let id = options.id || null;
    let dates = options.dates || null;
    let date_min = options.date_min || null;
    let date_max = options.date_max || null;
    let dates_col = null;
    if (options.dates)
    {
        dates_col = options.dates.col;
    }
    let sort = options.sort_by || null;
    let direction = options.direction || 'asc';
    let length = options.length || null;
    button_export = options.button_export || false;
    if (button_export == 'true')
    {
        button_export = true;
    } else if (button_export == 'false')
    {
        button_export = false;
    }
    let export_url = options.export_url || null;
    let buttons = options.buttons || null;
    let search = options.search == false ? false : true;
    let search_placeholder = options.search_placeholder ? options.search_placeholder : null;
    let search_width = options.search_width ? options.search_width : null;
    let fields = options.fields || null;
    let additional_html = options.additional_html || null;
    let form_id = options.form_id || null;
    let onload_function = options.onload_function || null;
    let show_loader = options.show_loader;
    if (show_loader === undefined)
    {
        show_loader = true;
    }



    return {


        options: options,
        container: container,
        search: search,
        search_placeholder: search_placeholder,
        search_width: search_width,
        search_val: '',
        active_url: data_url,
        page_url: data_url,
        button_export: button_export,
        export_url: export_url,
        buttons: buttons,
        fields: fields,
        dates: dates,
        date_min: date_min,
        date_max: date_max,
        date_col: dates_col,
        start_date: null,
        end_date: null,
        additional_html: additional_html,
        option_db_fields: [],
        sort: sort,
        direction: direction,
        length: length,
        page: '1',
        form_id: form_id,
        onload_function: onload_function,
        id: id,
        show_loader: show_loader,
        search_has_value: false,

        init()
        {
            if (this.show_loader == true)
            {
                show_loading();
            }
            this.init_table();
            this.load_table();
            this.show_options();
            this.refresh_table();
        },

        init_table()
        {
            this.container.innerHTML = ' \
            <div class="options-container"></div> \
            <div class="table-container"></div> \
            ';
        },

        load_table(url = null, to_excel = false)
        {

            let scope = this;

            // show_loading();

            if (!url)
            {
                url = scope.page_url + '?length=' + scope.length;
            }

            if (scope.form_id)
            {

                let form = document.getElementById(scope.form_id);

                [...form.elements].forEach((item) =>
                {
                    if (item.getAttribute('name'))
                    {
                        url += '&' + item.getAttribute('name') + '=' + item.value;
                    }
                });

            }

            axios.get(url, {
                params: {
                    to_excel: to_excel
                },
            })
                .then(function (response)
                {
                    if (to_excel == false)
                    {
                        let html = ' \
                        <div class="flex flex-col" \
                            x-init="'+ scope.onload_function + '"> \
                            <div class="-my-2 sm:-mx-6 lg:-mx-8 pb-2"> \
                                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"> \
                                    '+ response.data + ' \
                                </div> \
                            </div> \
                        </div> \
                        ';
                        scope.container.querySelector('.table-container').innerHTML = html;
                        scope.table_links();
                    } else
                    {
                        window.location = response.data.file;
                    }
                    hide_loading();

                    scope.container.querySelectorAll('tr').forEach(function (row)
                    {
                        if (row.querySelectorAll('th').length)
                        {
                            let first_th = row.querySelector('th:first-child');
                            let check_all = first_th.querySelector('input[type=checkbox]');
                            if (check_all)
                            {
                                check_all.addEventListener('change', function ()
                                {
                                    let checkboxes = scope.container.querySelectorAll('tbody tr td:first-child input[type=checkbox]');

                                    let checked = this.checked;

                                    checkboxes.forEach(function (checkbox)
                                    {
                                        checkbox.checked = checked;

                                        if (checkbox.checked)
                                        {
                                            checkbox.closest('tr').classList.remove('bg-gray-100');
                                            checkbox.closest('tr').classList.add('bg-blue-50');
                                        } else
                                        {
                                            checkbox.closest('tr').classList.remove('bg-blue-50');
                                        }
                                    });
                                });
                            }

                        } else if (row.querySelectorAll('td').length)
                        {
                            if (!row.classList.contains('bulk-options'))
                            {

                                let first_td = row.querySelector('td:first-child');

                                row.addEventListener('mouseenter', function ()
                                {
                                    row.querySelectorAll('td').forEach(function (td)
                                    {
                                        td.classList.add('border-b', 'border-t', 'border-blue-200');
                                    });
                                });
                                row.addEventListener('mouseleave', function ()
                                {
                                    row.querySelectorAll('td').forEach(function (td)
                                    {
                                        td.classList.remove('border-b', 'border-t', 'border-blue-200', 'bg-gray-100');
                                    });
                                });

                                if (first_td.querySelector('input[type=checkbox]'))
                                {
                                    first_td.querySelector('input[type=checkbox]').addEventListener('change', function ()
                                    {
                                        if (this.checked)
                                        {
                                            row.classList.add('bg-blue-50');
                                            row.classList.remove('bg-gray-100');
                                        } else
                                        {
                                            row.classList.remove('bg-blue-50');
                                        }
                                    });
                                }

                            }
                        }
                    });


                })
                .catch(function (error)
                {

                });

        },

        refresh_table()
        {
            let scope = this;
            document.querySelectorAll('.refresh-table').forEach(function (ele)
            {
                ele.addEventListener('click', function ()
                {
                    let active_table_id = ele.getAttribute('data-table');
                    let callback_function = ele.getAttribute('data-callback');
                    
                    if (active_table_id == table_id)
                    {
                        let refresh = function ()
                        {
                            scope.load_table(scope.active_url);
                        }
                        if (callback_function)
                        {
                            window[callback_function](ele, refresh);
                        }
                    }
                });
            });
        },

        to_excel()
        {
            show_loading();
            this.load_table(this.active_url, true);
        },

        show_options()
        {

            let scope = this;

            let options_html = `
            <div class="my-3">
                <div class="flex justify-between options-div">
                    <div class="flex">`;

            if (scope.search == true)
            {
                let search_width = scope.search_width ? scope.search_width : 'w-36';
                let search_placeholder = scope.search_placeholder ? scope.search_placeholder : 'Search';
                options_html += `
                    <div class="'+ search_width + '">
                        <div class="relative">
                            <input
                            type="text"
                            placeholder="`+ search_placeholder + `"
                            class="form-element input sm table-search"
                                data-label="Search"
                                @input.debounce.200ms="option_search($el.value); search_has_value = $el.value != '' ? true : false;">
                            <span class="absolute right-2 text-gray-400 bottom-1">
                                <span :class="search_has_value == true ? 'hidden' : ''">
                                    <i class="fa-solid fa-search"></i>
                                </span>
                                <span :class="search_has_value == false ? 'hidden' : ''"
                                class="cursor-pointer" @click="clear_search();">
                                    <i class="fa-solid fa-times"></i>
                                </span>
                            </span>
                        </div>
                    </div>
                    `;

            }

            if (scope.dates)
            {

                options_html += `
                        <div class="ml-6">
                            <div class="flex items-end justify-start space-x-4">
                                <div>
                                    <input
                                    type="date"
                                    class="form-element input sm"
                                        data-label="`+ scope.dates.text + `"
                                        placeholder="Start Date"
                                        min="`+ scope.date_min + `"
                                        max="`+ scope.date_max + `"
                                        x-ref="start_date"
                                        x-on:change="option_dates()">
                                </div>
                                <div> to </div>
                                <div>
                                    <input
                                    type="date"
                                    class="form-element input sm"
                                        data-label=""
                                        placeholder="End Date"
                                        min="`+ scope.date_min + `"
                                        max="`+ scope.date_max + `"
                                        x-ref="end_date"
                                        x-on:change="option_dates()">
                                </div>
                            </div>
                        </div>
                        `;

            }

            if (scope.fields)
            {

                Object.entries(scope.fields).forEach(([key, option]) =>
                {

                    let field_type = option.type;
                    let classes = option.classes;
                    let db_field = option.db_field;
                    let label = option.label;
                    let options = option.options;
                    let value = option.value;
                    let multiple = option.multiple;
                    let on_change = option.on_change || null;
                    let init = option.init || null;
                    let id = option.id || db_field;

                    let select_classes;
                    if (multiple)
                    {
                        select_classes = 'h-8  absolute z-10 block';
                    }

                    if (field_type === 'select')
                    {
                        options_html += ' \
                        <div class="ml-6 w-auto select-div"> \
                            <select \
                            id="'+ id + '" \
                            class="db_field form-element select sm '+ classes + ' ' + select_classes + '" \
                            '+ multiple + ' \
                            data-label="'+ label + '" \
                            data-db-field="'+ db_field + '" \
                            @change="' + on_change + '; setTimeout(function() { set_option_db_fields($el) }, 200)" \
                            x-init="'+ init + '">';
                        Object.entries(options).forEach(([key, option]) =>
                        {
                            let selected = option[0] == value ? 'selected' : '';
                            options_html += '<option value="' + option[0] + '" ' + selected + '>' + option[1] + '</option>';
                        });
                        options_html += '</select> \
                        </div>';
                    }
                });


            }

            options_html += ' \
                    </div>';

            if (scope.buttons)
            {
                options_html += ' \
                <div class="flex justify-end">';
                scope.buttons.forEach(function (button)
                {
                    let click = '';
                    if (button.click)
                    {
                        click = 'x-on:click="' + button.click + '"';
                    }
                    options_html += ' \
                    <div class="ml-2"> \
                        <a href="'+ button.url + '" class="button primary md" ' + click + '>' + button.html + '</a> \
                    </div> \
                    ';
                });
                options_html += ' </div>';
            }

            options_html += ' \
                </div>';

            if (scope.additional_html)
            {

                options_html += ' \
                <div class="ml-8 my-8"> \
                    '+ scope.additional_html + ' \
                </div>';

            }

            options_html += ' \
            </div>';

            scope.container.querySelector('.options-container').insertAdjacentHTML('beforeend', options_html);



        },

        clear_search()
        {
            let scope = this;
            document.querySelectorAll('.table-search').forEach(function (ele)
            {
                ele.value = '';
                scope.search_has_value = false;
                scope.load_table();
            });
        },

        table_links()
        {

            let scope = this;

            let table = scope.container.querySelector('table');

            table.querySelectorAll('th').forEach(function (th)
            {
                if (th.querySelector('a'))
                {
                    th.querySelector('a').classList.add('sort-by');
                }
            });


            scope.container.querySelectorAll('.pagination-link, .sort-by').forEach(function (link)
            {

                link.addEventListener('click', function (e)
                {

                    //show_loading();
                    e.preventDefault();

                    let url = link.href;
                    let href = new URL(url);
                    let params = new URLSearchParams(href.search);

                    /*
                        URL params
                        sort, direction, page, length, search, option_db_fields
                    */

                    if (link.classList.contains('sort-by'))
                    {
                        // leave out sort and direction because they are in the link already
                        scope.sort = params.get('sort');
                        scope.direction = params.get('direction');
                        url += '&search=' + scope.search_val + '&length=' + scope.length + '&page=' + scope.page;

                    } else
                    {

                        scope.page = params.get('page');
                        url += '&search=' + scope.search_val + '&length=' + scope.length + '&sort=' + scope.sort + '&direction=' + scope.direction;

                    }
                    scope.option_db_fields.forEach(function (field)
                    {
                        url += '&' + field.db_field + '=' + field.value;
                    });

                    scope.active_url = url;
                    scope.load_table(url);

                });

            });

        },

        set_option_db_fields(ele)
        {
            let scope = this;

            this.option_db_fields = [];

            let fields = ele.closest('.options-container').querySelectorAll('.db_field');

            if (fields)
            {

                fields.forEach(function (ele)
                {
                    let db_field = ele.getAttribute('data-db-field');
                    let value = ele.value;
                    // if multiple select
                    if (ele.hasAttribute('multiple'))
                    {
                        value = [...ele.selectedOptions]
                            .map(option => option.value);
                        value = value.join(',');
                    }

                    let field = {
                        db_field: db_field,
                        value: value
                    }

                    scope.option_db_fields.push(field);

                });
            }

            this.option_db_fields.forEach(function (field)
            {
                scope.add_url_param(field.db_field, field.value);
            });

            scope.load_table(scope.active_url);
        },

        option_db_field(db_field, ele)
        {
            let scope = this;

            let value = ele.value;
            // if multiple select
            if (ele.hasAttribute('multiple'))
            {
                value = [...ele.selectedOptions]
                    .map(option => option.value);
                value = value.join(',');
            }

            let field = {
                db_field: db_field,
                value: value
            }

            let option_added = false;
            this.option_db_fields.forEach(function (field)
            {
                if (field.db_field === db_field)
                {
                    field.value = value;
                    option_added = true;
                }
            });
            if (option_added === false)
            {
                this.option_db_fields.push(field);
            }

            this.option_db_fields.forEach(function (field)
            {
                scope.add_url_param(field.db_field, field.value);
            });

            scope.load_table(scope.active_url);

        },

        option_search(val)
        {

            let scope = this;

            scope.search_val = val;

            let url = scope.add_url_param('search', val.trim());

            scope.load_table(url);

        },

        table_change_length(val)
        {

            let scope = this;

            length = parseInt(val);
            scope.length = length;

            let url = scope.add_url_param('length', length);

            scope.load_table(url);

        },

        option_dates()
        {

            let scope = this;
            let start_date = scope.$refs.start_date.value;
            let end_date = scope.$refs.end_date.value;

            scope.start_date = start_date;
            scope.end_date = end_date;

            scope.add_url_param('date_col', scope.date_col);
            scope.add_url_param('start_date', start_date);
            scope.add_url_param('end_date', end_date);

            setTimeout(function ()
            {
                scope.load_table(scope.active_url);
            }, 100);
        },

        add_url_param(key, val)
        {
            let scope = this;

            let url = scope.page_url + '?search=' + scope.search_val + '&length=' + scope.length + '&sort=' + scope.sort + '&direction=' + scope.direction + '&start_date=' + scope.start_date + '&end_date=' + scope.end_date + '&date_col=' + scope.date_col;
            this.option_db_fields.forEach(function (field)
            {
                url += '&' + field.db_field + '=' + field.value;
            });

            let param = new RegExp('[&]*' + key + '=[,a-zA-Z0-9_-\\s]*');
            url = url.replace(param, '');
            url += '&' + key + '=' + val;

            scope.active_url = url;
            return url;

        },
    }

}







if (document.URL.match(/seller_concessions/))
{
    window.seller_concessions = function (add, seller_concessions_id = null, ListingId = null)
    {

        return {

            add: add,
            view_seller_concessions: false,
            show_mls_search_results: false,
            show_mls_section: true,
            show_details_section: false,
            concessions_available: 'no',
            contributions_available: 'no',
            show_back_button: true,
            show_delete: false,
            seller_concessions_id: seller_concessions_id,
            ListingId: ListingId,

            init()
            {
                if (this.add)
                {
                    this.view_seller_concessions = true;
                }
                this.get_seller_concessions();

                if (this.seller_concessions_id)
                {
                    this.edit_seller_concessions(this.seller_concessions_id, this.ListingId);
                }
            },

            get_seller_concessions()
            {
                let scope = this;
                let divs =
                    [
                        {
                            ele: scope.$refs.seller_concessions_div,
                            full_details: 'yes'
                        },
                        {
                            ele: scope.$refs.seller_concessions_add_div,
                            full_details: ''
                        }
                    ];



                divs.forEach(div =>
                {
                    axios.get('/agents/seller_concessions/get_seller_concessions', {
                        params: { full_details: div.full_details }
                    })
                        .then(function (response)
                        {
                            div.ele.innerHTML = response.data;
                        })
                        .catch(function (error)
                        {
                            console.log(error);
                        });
                });

            },

            search_mls(ele, mls)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Searching ... ');
                remove_form_errors();

                let formData = new FormData();
                formData.append('mls', mls);
                formData.append('concessions', 'yes');

                axios.post('/agents/seller_concessions/search_mls', formData)
                    .then(function (response)
                    {
                        scope.show_mls_search_results = true;
                        ele.innerHTML = button_html;
                        if (response.data.error)
                        {
                            scope.$refs.search_mls_results.innerHTML = response.data.error;
                            return;
                        }

                        scope.$refs.search_mls_results.innerHTML = response.data;
                        let modal_body = document.querySelector('[x-show="view_seller_concessions"]');
                        modal_body.scrollTop = modal_body.scrollHeight;
                        setTimeout(function ()
                        {
                            scope.$refs.search_mls_results_button.click();
                        }, 200);
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                        ele.innerHTML = button_html;
                    });
            },

            show_property_details(listing_id)
            {

                let scope = this;

                scope.clear_form();
                axios.get('/agents/seller_concessions/show_property_details', {
                    params: {
                        listing_id: listing_id
                    },
                })
                    .then(function (response)
                    {

                        scope.show_back_button = true;
                        scope.show_mls_section = false;
                        scope.show_details_section = true;

                        let listing = response.data.listing;

                        scope.$refs.listing_mls_id.innerHTML = listing_id;
                        scope.$refs.listing_image.src = listing.ListPictureURL;
                        scope.$refs.listing_address.innerHTML = listing.FullStreetAddress + '<br>' + listing.City + ', ' + listing.StateOrProvince + ' ' + listing.PostalCode;
                        scope.$refs.listing_mls_id_mobile.innerHTML = listing_id;
                        scope.$refs.mls_id.value = listing_id;
                        scope.$refs.listing_image_mobile.src = listing.ListPictureURL;
                        scope.$refs.listing_address_mobile.innerHTML = listing.FullStreetAddress + '<br>' + listing.City + ', ' + listing.StateOrProvince + ' ' + listing.PostalCode;
                        scope.$refs.listing_price.innerHTML = listing.ListPrice;
                        scope.$refs.listing_status.innerHTML = listing.MlsStatus;

                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });

            },

            edit_seller_concessions(seller_concessions_id, listing_id)
            {
                let scope = this;

                axios.get('/agents/seller_concessions/edit_seller_concessions', {
                    params: {
                        seller_concessions_id: seller_concessions_id,
                        listing_id: listing_id
                    },
                })
                    .then(function (response)
                    {
                        scope.view_seller_concessions = true;
                        scope.show_mls_section = false;
                        scope.show_details_section = true;
                        scope.show_back_button = false;
                        scope.show_delete = true;

                        let listing = response.data.listing;
                        scope.$refs.seller_concessions_id.value = seller_concessions_id;
                        scope.$refs.listing_mls_id.innerHTML = listing.ListingId;
                        scope.$refs.listing_image.src = listing.ListPictureURL;
                        scope.$refs.listing_address.innerHTML = listing.FullStreetAddress + '<br>' + listing.City + ', ' + listing.StateOrProvince + ' ' + listing.PostalCode;
                        scope.$refs.listing_mls_id_mobile.innerHTML = listing.ListingId;
                        scope.$refs.mls_id.value = listing.ListingId;
                        scope.$refs.listing_image_mobile.src = listing.ListPictureURL;
                        scope.$refs.listing_address_mobile.innerHTML = listing.FullStreetAddress + '<br>' + listing.City + ', ' + listing.StateOrProvince + ' ' + listing.PostalCode;
                        scope.$refs.listing_price.innerHTML = listing.ListPrice;
                        scope.$refs.listing_status.innerHTML = listing.MlsStatus;

                        let concessions = response.data.seller_concessions;
                        document.querySelector('[name="concessions_available"][value="' + concessions.concessions_available + '"]').checked = true;
                        if (concessions.concessions_available == 'yes')
                        {
                            scope.concessions_available = 'yes';
                            scope.$refs.concessions_amount.value = concessions.concessions_amount;
                            scope.$refs.concessions_percent.value = concessions.concessions_percent;
                        }
                        document.querySelector('[name="contributions_available"][value="' + concessions.contributions_available + '"]').checked = true;
                        if (concessions.contributions_available == 'yes')
                        {
                            scope.contributions_available = 'yes';
                            scope.$refs.contributions_amount.value = concessions.contributions_amount;
                            scope.$refs.contributions_percent.value = concessions.contributions_percent;
                        }

                        global_format_money();

                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });

            },

            save_seller_concessions(ele, form)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving Seller Concessions ... ');
                remove_form_errors();

                let formData = new FormData(form);

                axios.post('/agents/seller_concessions/save_seller_concessions', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Seller Concessions Saved Successfully');
                        scope.view_seller_concessions = false;
                        scope.show_details_section = false;
                        scope.show_mls_section = true;
                        scope.get_seller_concessions();
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            delete_seller_concessions(ele, seller_concessions_id)
            {
                if (confirm('Are you sure you want to delete the Seller Concessions?'))
                {
                    let scope = this;
                    let button_html = ele.innerHTML;
                    show_loading_button(ele, 'Deleting Seller Concessions ... ');
                    remove_form_errors();

                    let formData = new FormData();
                    formData.append('seller_concessions_id', seller_concessions_id);

                    axios.post('/agents/seller_concessions/delete_seller_concessions', formData)
                        .then(function (response)
                        {
                            ele.innerHTML = button_html;
                            notify('success', 'Seller Concessions Deleted Successfully');
                            scope.get_seller_concessions();
                            scope.view_seller_concessions = false;
                        })
                        .catch(function (error)
                        {
                            display_errors(error, ele, button_html);
                        });
                }
            },

            clear_form()
            {
                let form = this.$refs.seller_concessions_form;
                form.reset();
                this.show_mls_section = true;
                this.show_details_section = false;
                this.concessions_available = 'no';
                this.contributions_available = 'no';
                this.show_delete = false;
                this.show_mls_search_results = false;
                this.$refs.mls.value = '';
                this.$refs.seller_concessions_id.value = '';
            },

        }

    }

}
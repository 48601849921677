if (document.URL.match(/create_email_campaign/))
{

    window.create_email_campaign = function ()
    {

        return {
            batch_id: null,
            active_tab: 0,
            show_preview_email: false,
            show_view_list: false,
            list_type: 'Automated', // Automated, Manual
            location_type: 'location', // location, distance
            manual_search_id: null,
            counties_label: 'Choose counties...',
            email_selected: false,
            recipients_selected: false,
            sending_details_selected: false,
            sort_order: 'asc',
            show_review_email_campaign: false,
            send_now: 2,
            show_add_category: false,
            states: [],
            state_values: [
                { id: 1, name: 'MD', disabled: false },
                { id: 2, name: 'VA', disabled: false },
                { id: 3, name: 'DC', disabled: false },
                { id: 4, name: 'DE', disabled: false },
                { id: 5, name: 'PA', disabled: false },
            ],
            get states_label()
            {
                if (this.states.length === 0)
                {
                    this.$refs.listbox_button.disabled = true;
                    this.$refs.listbox_button.classList.add('cursor-not-allowed', 'opacity-50');
                    return 'Choose states...';
                }
                this.$refs.listbox_button.disabled = false;
                this.$refs.listbox_button.classList.remove('cursor-not-allowed', 'opacity-50');
                return this.states.length === 1 ? this.states[0].name : `${this.states.length} selected`;
            },
            counties: [],
            county_values: [],


            init()
            {
                this.get_counties();
                this.set_counties_label();
                this.get_templates();
            },

            next_tab()
            {
                this.active_tab++;
            },

            prev_tab()
            {
                this.active_tab--;
            },

            get_templates()
            {
                let scope = this;
                axios.get('/email_marketing/get_templates')
                    .then(function (response)
                    {
                        document.getElementById('templates_div').innerHTML = response.data;
                    });
            },

            set_counties_label()
            {
                if (this.counties.length === 0)
                {
                    this.counties_label = 'Choose counties...';
                }
                this.counties_label = this.counties.length === 1 ? this.counties[0].name : `${this.counties.length} selected`;
            },

            set_recipients_selected()
            {
                document.querySelectorAll('input[name="list_slug"]').forEach(function (ele)
                {
                    ele.checked = false;
                });
                this.recipients_selected = false;
            },

            preview_email(template_id)
            {
                let scope = this;
                axios.get('/email_marketing/preview_email', {
                    params: {
                        template_id: template_id,
                    },
                })
                    .then(function (response)
                    {
                        scope.$refs.preview_email_div.innerHTML = '';
                        scope.show_preview_email = true;
                        let iframe = document.createElement('iframe');
                        iframe.srcdoc = response.data.html;
                        iframe.classList.add('w-full', 'h-[1000px]');
                        scope.$refs.preview_email_div.appendChild(iframe);
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });
            },


            manual_search()
            {
                let scope = this;
                let miles = document.querySelector('input[name="miles"]').value || null;
                let location = document.querySelector('input[name="location"]').value || null;
                let office_name = document.querySelector('input[name="office_name"]').value || null;
                let producers = document.querySelector('input[name="producers"]:checked').value || null;

                scope.$refs.agents_count.innerHTML = '<span class="text-base font-normal">Getting Count <i class="fas fa-spinner fa-spin ml-2"></i></span>';

                document.querySelectorAll('.results-only').forEach(function (ele)
                {
                    ele.classList.remove('hidden');
                });

                let new_id = Math.random();
                scope.manual_search_id = new_id;
                setTimeout(function ()
                {
                    if (new_id != scope.manual_search_id)
                    {
                        return;
                    }


                    axios.get('/email_marketing/manual_search', {
                        params: {
                            location_type: scope.location_type,
                            states: scope.states,
                            counties: scope.counties,
                            miles: miles,
                            location: location,
                            office_name: office_name,
                            producers: producers,
                        },
                    })
                        .then(function (response)
                        {
                            // if old request
                            if (new_id != scope.manual_search_id)
                            {
                                return;
                            }
                            scope.$refs.agents_count.innerHTML = response.data.agents_count;
                            scope.recipients_selected = true;
                            scope.batch_id = response.data.batch_id;
                        })
                        .catch(function (error)
                        {
                            console.log(error);
                        });
                }, 2000);





            },

            get_counties()
            {
                let scope = this;
                scope.counties = [];
                axios.get('/email_marketing/get_counties')
                    .then(function (response)
                    {
                        if (response.data.counties)
                        {
                            scope.county_values = response.data.counties;
                        }
                    });

            },

            set_counties()
            {
                let scope = this;
                scope.counties = [];
                document.querySelectorAll('.county-item').forEach(function (ele)
                {
                    ele.classList.add('hidden');
                });
                scope.states.forEach(function (state)
                {
                    document.querySelectorAll('.county.' + state.name).forEach(function (ele)
                    {
                        ele.closest('li').classList.remove('hidden');
                    });
                });
                scope.set_counties_label();
                // document.querySelectorAll('.county').forEach(function (ele)
                // {
                //     let state = '';
                //     scope.states.forEach(function (state)
                //     {
                //         if (ele.classList.contains(state.name))
                //         {
                //             ele.closest('li').classList.remove('hidden');
                //         } else
                //         {
                //             ele.closest('li').classList.add('hidden');
                //         }
                //     });
                // });
            },

            view_list(ele = null, sort_by = null)
            {
                let button_html = null;
                let scope = this;
                if (ele)
                {
                    button_html = ele.innerHTML;
                    show_loading_button(ele, 'Loading ...');
                }
                axios.get('/email_marketing/view_list', {
                    params: {
                        sort_by: sort_by,
                        sort_order: scope.sort_order,
                    },
                })
                    .then(function (response)
                    {
                        scope.sort_order = scope.sort_order == 'asc' ? 'desc' : 'asc';
                        scope.$refs.view_list_div.innerHTML = response.data;
                        scope.show_view_list = true;
                        if (ele)
                        {
                            ele.innerHTML = button_html;
                        }
                    })
                    .catch(function (error)
                    {
                        console.log(error);
                        if (ele)
                        {
                            ele.innerHTML = button_html;
                        }
                    });
            },

            update_sending_details_selected()
            {
                let scope = this;
                if (scope.$refs.name.value && scope.$refs.user_id.value && scope.$refs.subject.value && scope.$refs.send_date.value && scope.$refs.category.value)
                {
                    scope.sending_details_selected = true;
                } else
                {
                    scope.sending_details_selected = false;
                }
            },

            add_new_category(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Adding ... ');
                remove_form_errors();
                
                let new_category = scope.$refs.new_category.value;
                let formData = new FormData();
                formData.append('new_category', new_category);
                
                axios.post('/email_marketing/add_new_category', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'New category added successfully');
                        scope.$refs.new_category.value = '';
                        scope.show_add_category = false;
                        scope.$refs.category.innerHTML += `<option value="${new_category}" selected>${new_category}</option>`;
                        reset_select(scope.$refs.category);
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            review_email_campaign(ele)
            {
                let scope = this;

                let checked_list = document.querySelector('input[name="list_slug"]:checked');
                let template_id = document.querySelector('input[name="template_id"]:checked').value;
                let list_slug = checked_list ? checked_list.value : null;
                let list_name = checked_list ? checked_list.getAttribute('data-list-name') : null;
                let list_count = checked_list ? checked_list.getAttribute('data-list-count') : null;
                let name = document.querySelector('input[name="name"]').value;
                let user_id = document.querySelector('select[name="user_id"]').value;
                let subject = document.querySelector('input[name="subject"]').value;
                let send_now = document.querySelector('input[name="send_now"]:checked').value;
                let send_date = document.querySelector('input[name="send_date"]').value;
                let category = document.querySelector('select[name="category"]').value;
                let button_html = ele.innerHTML;
                

                if (send_now == '1')
                {
                    send_date = 'now';
                }
                show_loading_button(ele, 'Processing ... ');
                remove_form_errors();

                let formData = new FormData();
                formData.append('batch_id', scope.batch_id);
                formData.append('template_id', template_id);
                formData.append('list_type', scope.list_type);
                formData.append('list_slug', list_slug);
                formData.append('list_name', list_name);
                formData.append('list_count', list_count);
                formData.append('name', name);
                formData.append('user_id', user_id);
                formData.append('subject', subject);
                formData.append('send_date', send_date);
                formData.append('category', category);
                axios.post('/email_marketing/review_email_campaign', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        scope.$refs.review_email_campaign_div.innerHTML = response.data;
                        scope.show_review_email_campaign = true;
                        setTimeout(function ()
                        {
                            let iframe = document.createElement('iframe');
                            let html = document.getElementById('preview_email_template').innerHTML;
                            iframe.srcdoc = html;
                            iframe.classList.add('w-full', 'h-[1600px]');
                            scope.$refs.review_email_div.appendChild(iframe);
                        }, 300);
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            send_email_campaign(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                remove_form_errors();

                let checked_list = document.querySelector('input[name="list_slug"]:checked');
                let template_id = document.querySelector('input[name="template_id"]:checked').value;
                let list_slug = checked_list ? checked_list.value : null;
                let list_name = checked_list ? checked_list.getAttribute('data-list-name') : null;
                let list_count = checked_list ? checked_list.getAttribute('data-list-count') : null;
                let name = document.querySelector('input[name="name"]').value;
                let user_id = document.querySelector('select[name="user_id"]').value;
                let subject = document.querySelector('input[name="subject"]').value;
                let send_date = document.querySelector('input[name="send_date"]').value;
                let category = document.querySelector('select[name="category"]').value;
                let formData = new FormData();
                formData.append('batch_id', scope.batch_id);
                formData.append('template_id', template_id);
                formData.append('list_type', scope.list_type);
                formData.append('list_slug', list_slug);
                formData.append('list_name', list_name);
                formData.append('list_count', list_count);
                formData.append('name', name);
                formData.append('user_id', user_id);
                formData.append('subject', subject);
                formData.append('send_date', send_date);
                formData.append('category', category);
                axios.post('/email_marketing/send_email_campaign', formData)
                    .then(function (response)
                    {
                        // set cookie
                        document.cookie = 'email_campaign_status=success; path=/';
                        window.location.href = '/email_marketing';

                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            delete_template(ele, template_id)
            {
                if (confirm('Are you sure you want to delete this template?'))
                {
                    let scope = this;
                    let button_html = ele.innerHTML;
                    show_loading_button(ele, 'Deleting ... ');
                    remove_form_errors();

                    let formData = new FormData();
                    formData.append('template_id', template_id);

                    axios.post('/email_marketing/delete_template', formData)
                        .then(function (response)
                        {
                            ele.innerHTML = button_html;
                            scope.get_templates();

                        })
                        .catch(function (error)
                        {
                            display_errors(error, ele, button_html);
                            ele.innerHTML = button_html;
                        });
                }
            },

        }

    }

}
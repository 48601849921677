document.addEventListener('keyup', (e) =>
    {
        if (e.key === 'Tab')
        {
            if(e.target.type != 'select' && e.target.type != 'select-one') {
                hide_dropdowns();
            }
        }
});
    
window.reset_select = function (ele) {
    ele.classList.remove('label-added');
}

window.form_elements = function ()
{

        

    document.querySelectorAll('.form-element').forEach(function (element)
    {

        let classes = element.classList;

        let size = '';
        if (classes.contains('sm'))
        {
            size = 'sm';
        } else if (classes.contains('md'))
        {
            size = 'md';
        } else if (classes.contains('lg'))
        {
            size = 'lg';
        } else if (classes.contains('xl'))
        {
            size = 'xl';
        }

        if (!classes.contains('label-added') && !classes.contains('range'))
        {

            let type = classes.contains('checkbox') || classes.contains('radio') ? 'checkbox' : 'text';
            if (element.getAttribute('type') == 'file')
            {
                type = 'file';
            }

            let label = '';
            let label_text = element.getAttribute('data-label') || null;
            let parent = element.parentNode;

            let existing_label = element.parentNode.querySelector('label');
            if (existing_label)
            {
                existing_label.remove();
            }

            if (element.parentNode.tagName !== 'LABEL' && element.getAttribute('type') != 'file' && label_text)
            {
                label = document.createElement('LABEL');
                //parent.replaceChild(label, element);
                if (type == 'checkbox')
                {
                    parent.append(label);
                } else
                {
                    parent.prepend(label);
                }

            } else
            {
                label = element.parentNode;
            }


            if (!size)
            {
                size = 'md';
            }
            label.classList.add(type, size);

            let element_id = element.id ? element.id : 'input_' + (Date.now() * Math.random() * 1000).toFixed(0);
            element.id = element_id;

            element.parentNode.classList.add('form-element-parent');

            if (label_text)
            {
                label.classList.add('form-element-label');
                label.setAttribute('for', element_id);
                if (type == 'checkbox')
                {
                    // label.classList.add('mt-2');
                    label.insertAdjacentHTML('beforeend', '<div class="label-text inline-block">' + label_text + '</div>');
                    if (size == 'xl')
                    {
                        element.classList.add('align-text-bottom');
                    }
                    label.parentNode.classList.add('flex', 'items-center');
                } else
                {
                    label.innerHTML = label_text;
                }
            }

            if (element.getAttribute('type') == 'file')
            {
                let placeholder = 'Select File';
                if (element.hasAttribute('multiple'))
                {
                    placeholder = 'Select Files';
                }
                label = document.createElement('LABEL');
                let html = ' \
                <div class="flex justify-start w-full"> \
                    <div class="flex items-center bg-gray-100 text-gray-700 text-sm p-2 whitespace-nowrap rounded-l"> \
                        <i class="fa-duotone fa-upload mr-2"></i> '+ placeholder + ' \
                    </div> \
                    <div class="flex-1"> \
                        <div class="file-names ' + element_id + ' text-xs max-h-24 overflow-y-auto p-2 w-full"></div> \
                    </div> \
                </div>';
                label.innerHTML = html;
                label.classList.add('form-element-label', 'file');
                label.setAttribute('for', element_id);
                parent.append(label);

                element.addEventListener('change', function ()
                {
                    setTimeout(function ()
                    {
                        show_file_names(element);
                    }, 300);
                });
            }

            classes.add('label-added');

            if (element.hasAttribute('required') || element.classList.contains('required'))
            {
                element.parentNode.insertAdjacentHTML('beforeend', '<div class="relative"> <span class="text-red-500 text-xxs error-message h-4 inline-block absolute top-0"></span> </div>');
            }

            if (classes.contains('select') && !element.hasAttribute('multiple'))
            {
                single_select(element);
            }

        }

        if (element.disabled)
        {
            if (element.parentNode.querySelector('.form-element-label'))
            {
                element.parentNode.querySelector('.form-element-label').classList.add('opacity-40');
            }
        } else
        {
            if (element.parentNode.querySelector('.form-element-label'))
            {
                element.parentNode.querySelector('.form-element-label').classList.remove('opacity-40');
            }
        }
        

    });

}

function single_select(element)
{
    let option_values = [...element.options].map(o => o.value);
    let option_texts = [...element.options].map(o => o.text);

    let dropdown = create_dropdown(element, option_values, option_texts);

    element.parentNode.appendChild(dropdown);

    let dropdown_div = dropdown.querySelector('.dropdown-div');
    let dropdown_search = dropdown.querySelector('.dropdown-search');

    element.addEventListener('mousedown', function (e)
    {
        e.preventDefault();
        show_dropdown(element, dropdown_div, dropdown_search);
    });

    element.addEventListener('focus', function (e)
    {
        if (dropdown_div.classList.contains('hidden'))
        {
            show_dropdown(element, dropdown_div, dropdown_search);
        }
    });

    if (dropdown_search)
    {
        dropdown_search.addEventListener('keyup', function (e)
        {
            search(dropdown_div, dropdown_search, dropdown);
        });
    }

    // element.querySelector('option[value="' + element.options[element.selectedIndex].value + ']"').click();

    dropdowns_click(element, dropdown, dropdown_div, dropdown_search);
}

function create_dropdown(element, option_values, option_texts)
{
    let dropdown = document.createElement('div');
    dropdown.classList.add('relative');

    let dropdown_html = `
    <div class="overflow-auto">
    <div class="dropdown-div absolute right-0 z-20 hidden w-full border-2 rounded-md bg-white shadow-lg text-gray-600 border-blue-200"
        x-show="!$el.classList.contains('hidden')">
        <div class="px-2 pt-2 pb-1 flex items-center space-x-2 w-full" role="none">`;

    if (!element.classList.contains('no-search'))
    {
        let width = 'w-full';
        if (!element.classList.contains('no-cancel'))
        {
            width = 'w-3/5';
        }
        dropdown_html += `
        <div class="`+ width + `">
            <input type="text" class="form-element input sm border-bottom dropdown-search" placeholder="Search" aria-label="Search">
        </div>`;
    }
    if (!element.classList.contains('no-cancel'))
    {
        dropdown_html += `
        <div class="text-gray-400 text-sm clear-option cursor-pointer flex items-center justify-end flex-grow pr-2" @click="hide_dropdowns($el)"><i class="fa-solid fa-xmark mr-2"></i> Clear
        </div>`;
    }


    dropdown_html += `
        </div>
    <div class="px-1 pt-0 pb-1 max-h-300-px overflow-y-auto" role="none">`;

    for (let i = 0; i < option_values.length; i++)
    {
        let classes = option_texts[i] != '' ? 'select-option options hover:text-white hover:bg-blue-600 rounded' : 'select-option hover:text-gray-700 hover:bg-gray-100';

        let text = option_texts[i];
        if (text != '')
        {

            dropdown_html += `
            <div class="border-top cursor-pointer ${classes} py-1 px-1 text-sm"
                data-value="${option_values[i]}"
                data-search-text="${option_texts[i]}">
                ${text}
            </div>
            `;

        }
    }

    dropdown_html += `
        </div>
        </div>
    </div>`;

    dropdown.innerHTML = dropdown_html;

    return dropdown;
}

function dropdowns_click(element, dropdown, dropdown_div, dropdown_search)
{
    dropdown.querySelectorAll('.select-option').forEach(function (option)
    {
        option.addEventListener('click', function (e)
        {
            if (dropdown.querySelector('.active-option'))
            {
                dropdown.querySelector('.active-option').classList.remove('active-option');
            }
            let value = e.target.getAttribute('data-value');
            element.value = value;
            let event = new Event('change');
            element.dispatchEvent(event);
            hide_dropdowns();
            show_clear_option(element, dropdown_div);
            if (dropdown_search)
            {
                dropdown_search.value = '';
                search(dropdown_div, dropdown_search, dropdown);
            }
            if (dropdown.querySelector('.select-option[data-value="' + value + '"]'))
            {
                dropdown.querySelector('.select-option[data-value="' + value + '"]').classList.add('active-option');
            }
        })
    });
}

function show_dropdown(element, dropdown_div, dropdown_search)
{
    hide_dropdowns();

    show_clear_option(element, dropdown_div);
    
    if (dropdown_div.classList.contains('hidden'))
    {
        dropdown_div.classList.remove('hidden');
        dropdown_div.style.display = 'inline-block';
        if (dropdown_search)
        {
            dropdown_search.focus();
        } else
        {
            element.focus();
        }
        if (element.value != '')
        {
            let selected_option = dropdown_div.querySelectorAll('[data-value="' + element.value + '"]')[0];
            if (selected_option)
            {
                let parent = selected_option.parentNode;
                parent.scrollTo({
                    top: selected_option.offsetTop - 75,
                });
                selected_option.classList.add('active-option');
            }
        }
    } else
    {
        dropdown_div.classList.add('hidden');
        dropdown_div.style.display = 'none';
    }


    document.addEventListener('click', function (event)
    {
        const box = element.parentNode;

        if (!box.contains(event.target))
        {
            dropdown_div.classList.add('hidden');
            dropdown_div.style.display = 'none';
        }

    });



}

function show_clear_option(element, dropdown_div)
{
    let clear_option = dropdown_div.querySelector('.clear-option');
    if (clear_option)
    {
        if (element.value != '')
        {
            clear_option.classList.remove('hidden');
        } else
        {
            clear_option.classList.add('hidden');
        }
    }
}

function search(dropdown_div, dropdown_search, dropdown)
{
    let search = dropdown_search.value.trim();

    remove_active_options(dropdown);

    if (search.length > 0)
    {

        dropdown.querySelectorAll('.options').forEach(function (option)
        {

            if (option.getAttribute('data-search-text').match(new RegExp(search, 'i')))
            {
                option.classList.remove('hidden');
            } else
            {
                option.classList.add('hidden');
            }
        });

        if (dropdown.querySelectorAll('.options:not(.hidden)').length > 0)
        {
            dropdown.querySelectorAll('.options:not(.hidden)')[0].classList.add('text-white', 'bg-gray-400');
            dropdown.addEventListener('keypress', function (event)
            {
                if (event.key === 'Enter')
                {
                    if (!dropdown_div.classList.contains('hidden'))
                    {
                        event.preventDefault();
                        if (dropdown.querySelector('.options.bg-gray-400'))
                        {
                            dropdown.querySelector('.options.bg-gray-400').click();
                        }
                    }
                }
            });

        }

    } else
    {
        show_all_options(dropdown);
    }
}

function show_all_options(dropdown)
{
    dropdown.querySelectorAll('.options').forEach(function (option)
    {
        option.classList.remove('hidden');
    });
}

function remove_active_options(dropdown)
{
    if (dropdown.querySelectorAll('.options.bg-gray-400').length > 0)
    {
        dropdown.querySelectorAll('.options.bg-gray-400').forEach(function (option)
        {
            option.classList.remove('text-white', 'bg-gray-400');
        });
    }
}

window.hide_dropdowns = function (ele = null)
{
    document.querySelectorAll('.dropdown-div').forEach(function (dropdown)
    {
        dropdown.classList.add('hidden');
        dropdown.style.display = 'none';
        if (dropdown.querySelector('.dropdown-search'))
        {
            dropdown.querySelector('.dropdown-search').value = '';
        }
        remove_active_options(dropdown);
        show_all_options(dropdown);
    });
    if (ele)
    {
        ele.closest('.form-element-parent').querySelector('select').value = '';
    }
}
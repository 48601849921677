window.run_webhook = function () {
    return {
        init() {
            
        },
        send_request()
            {
                axios.get('/tools/webhooks/test_webhook', {
                    params: {
                        id: 7
                    },
                })
                    .then(function (response)
                    {
                        let params = JSON.parse(response.data);

                        axios.post('/webhooks/heavy_hammer', params)
                            .then(function (response)
                            {
                                console.log(response);
                            })
                            .catch(function (error)
                            {
                                console.log(error);
                            });

                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });

            }
    }
}


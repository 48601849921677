window.email_marketing = function ()
{
    return {
        show_success: false,
        calendar: null,
        events: [],
        show_calendar: false,
        show_list_stats: false,

        init()
        {
            this.get_events();
            // if cookie exists
            if (document.cookie.includes('email_campaign_status=success'))
            {
                this.show_success = true;
                document.cookie = 'email_campaign_status=; path=/';
            }
        },

        get_events()
        {
            let scope = this;
            axios.get('/email_marketing/get_events')
                .then(response =>
                {
                    scope.events = response.data;
                    scope.calendar_init();
                });
        },

        view_list_stats()
        {
            axios.get('/email_marketing/get_list_stats')
                .then(response =>
                {
                    this.$refs.list_stats_div.innerHTML = response.data;
                    this.show_list_stats = true;
                });
        },

        calendar_init()
        {
            this.calendar = new FullCalendar.Calendar(this.$refs.calendar_div, {
                events: this.events,
                initialDate: '2025-01-01',
                initialView: 'dayGridMonth',
                selectable: false,

                eventClick: (info) =>
                {
                    info.jsEvent.preventDefault();
                    if (info.event.url)
                    {
                        window.open(info.event.url);
                    }
                },

            })

        },

        getEventIndex(info)
        {
            return this.events.findIndex((event) => event.id == info.event.id)
        },

        view_calendar()
        {
            this.show_calendar = true;
            setTimeout(() =>
            {
                this.calendar.render();
            }, 500);
        },


    }
}

window.update_stats_and_statuses = function (ele, refresh)
{
    show_loading();
    ele.querySelector('i').classList.add('fa-spin');
    axios.get('/email_marketing/update_stats_and_statuses')
        .then(function (response)
        {
            setTimeout(() =>
            {
                // document.querySelector('.refresh-table').click();
                ele.querySelector('i').classList.remove('fa-spin');
                notify('success', 'Email stats and statuses updated');
                if (refresh)
                {
                    refresh();
                    hide_loading();
                }
                hide_loading();
            }, 3000);
        })
        .catch(function (error)
        {
            ele.querySelector('i').classList.remove('fa-spin');
        });
}
if (document.URL.match(/tests/))
{


    window.test = function ()
    {
        return {

            init()
            {
            },

            send_request()
            {
                axios.get('/tests/get_webhook_data', {
                    params: {
                        id: 7
                    },
                })
                    .then(function (response)
                    {

                        // post the response to the webhook
                        // json response to form data
                        let params = JSON.parse(response.data);

                        axios.post('/webhooks/heavy_hammer', params)
                            .then(function (response)
                            {
                                console.log(response);
                            })
                            .catch(function (error)
                            {
                                console.log(error);
                            });

                    })
                    .catch(function (error)
                    {
                        console.log(error);
                    });

            }

        }
    }

}

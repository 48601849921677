window.add_leads_to_nutshell = function ()
{
    return {

        nutshell_list_type: 'all',
        leads_being_added: false,
        show_review_nutshell_list: false,
        location_type: 'location', // location, distance
        counties_label: 'Choose counties...',
        states: [],
        state_values: [
            { id: 1, name: 'MD', disabled: false },
            { id: 2, name: 'VA', disabled: false },
            { id: 3, name: 'DC', disabled: false },
            { id: 4, name: 'DE', disabled: false },
            { id: 5, name: 'PA', disabled: false },
        ],
        get states_label()
        {
            if (this.states.length === 0)
            {
                this.$refs.listbox_button.disabled = true;
                this.$refs.listbox_button.classList.add('cursor-not-allowed', 'opacity-50');
                return 'Choose states...';
            }
            this.$refs.listbox_button.disabled = false;
            this.$refs.listbox_button.classList.remove('cursor-not-allowed', 'opacity-50');
            return this.states.length === 1 ? this.states[0].name : `${this.states.length} selected`;
        },
        counties: [],
        county_values: [],

        init()
        {
            this.get_leads_added(true);

            this.get_counties();
            this.set_counties_label();
        },


        set_counties_label()
        {
            if (this.counties.length === 0)
            {
                this.counties_label = 'Choose counties...';
            }
            this.counties_label = this.counties.length === 1 ? this.counties[0].name : `${this.counties.length} selected`;
        },

        get_counties()
        {
            let scope = this;
            scope.counties = [];
            axios.get('/email_marketing/get_counties')
                .then(function (response)
                {
                    if (response.data.counties)
                    {
                        scope.county_values = response.data.counties;
                    }
                });

        },

        set_counties()
        {
            let scope = this;
            scope.counties = [];
            document.querySelectorAll('.county-item').forEach(function (ele)
            {
                ele.classList.add('hidden');
            });
            scope.states.forEach(function (state)
            {
                document.querySelectorAll('.county.' + state.name).forEach(function (ele)
                {
                    ele.closest('li').classList.remove('hidden');
                });
            });
            scope.set_counties_label();

        },

        review_nutshell_list(ele)
        {
            let scope = this;
            let amount_of_leads_to_add = scope.$refs.amount_of_leads_to_add.value;
            let assign_to_user_id = scope.$refs.assign_to_user_id.value;
            let pipeline_name = scope.$refs.pipeline_name.value;
            let nutshell_list_type = scope.nutshell_list_type;

            let location_type = scope.location_type;
            let miles = document.querySelector('input[name="miles"]').value;
            let location = document.querySelector('input[name="location"]').value;
            let office_name = document.querySelector('input[name="office_name"]').value;
            let states = JSON.stringify(scope.states);
            let counties = JSON.stringify(scope.counties);

            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Getting Data... ');
            remove_form_errors();

            let formData = new FormData();
            formData.append('amount_of_leads_to_add', amount_of_leads_to_add);
            formData.append('assign_to_user_id', assign_to_user_id);
            formData.append('pipeline_name', pipeline_name);
            formData.append('nutshell_list_type', nutshell_list_type);
            formData.append('location_type', location_type);
            formData.append('miles', miles);
            formData.append('location', location);
            formData.append('office_name', office_name);
            formData.append('states', states);
            formData.append('counties', counties);

            axios.post('/marketing/leads/agents/review_nutshell_list', formData)
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    scope.$refs.review_nutshell_list_div.innerHTML = response.data;
                    scope.show_review_nutshell_list = true;

                    axios.get('/marketing/leads/agents/get_nutshell_list_agents')
                        .then(function (response)
                        {
                            console.log(document.getElementById('nutshell_list_agents_div'));
                            document.getElementById('nutshell_list_agents_div').innerHTML = response.data;
                        });
                })
                .catch(function (error)
                {
                    display_errors(error, ele, button_html);
                    ele.innerHTML = button_html;
                });


        },

        add_nutshell_leads(ele)
        {
            let scope = this;

            let button_html = ele.innerHTML;
            show_loading_button(ele, 'Adding Leads... ');

            axios.post('/marketing/leads/agents/add_nutshell_leads')
                .then(function (response)
                {
                    ele.innerHTML = button_html;
                    scope.get_leads_added();
                    notify('success', 'Leads added to Nutshell');
                    scope.show_review_nutshell_list = false;
                    setTimeout(() =>
                    {
                        scope.reset_form();
                    }, 1000);
                })
                .catch(function (error)
                {
                    ele.innerHTML = button_html;
                });
        },

        reset_form()
        {
            let scope = this;
            scope.states = [];
            scope.counties = [];
            scope.counties_label = 'Choose counties...';
            scope.location_type = 'location';
            scope.nutshell_list_type = 'all';

            document.querySelector('[name=amount_of_leads_to_add]').value = '';
            document.querySelector('[name=assign_to_user_id]').value = '';
            document.querySelector('[name=pipeline_name]').value = '';
            document.querySelector('[name=miles]').value = '';
            document.querySelector('[name=location]').value = '';
            document.querySelector('[name=office_name]').value = '';
            scope.get_leads_added();

        },

        get_leads_added(initial_call = false)
        {
            let scope = this;
            let leads_added_div = document.getElementById('leads_added_div');
            leads_added_div.innerHTML = '<div class="w-full text-center"><div class="text-gray-700 pt-16">Loading Data... <i class="fas fa-spinner fa-spin ml-2"></i></div></div>';
            axios.get('/marketing/leads/agents/get_leads_added')
                .then(function (response)
                {
                    leads_added_div.innerHTML = response.data;
                    if (!initial_call)
                    {
                        scope.leads_being_added = true;
                        window.scrollTo(0, 0);
                    }

                });
        },
    }
}

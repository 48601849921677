if (document.URL.match(/queue_monitoring/))
{

    window.queue_monitor = function ()
    {
        
        return {

            init()
            {
                this.get_stats();
                setInterval(this.get_stats, 5000);
                // this.get_jobs();
            },

            get_jobs()
            {
                axios.get('/jobs')
                .then(response => {
                    document.getElementById('jobs_div').innerHTML = response.data;
                });
            },

            get_stats()
            {
                let scope = this;
                axios.get('/admin/queue_monitoring/get_stats')
                .then(response => {
                    let stats = response.data;
                    let html = '';
                    for (let job in stats)
                    {
                        html += `<div class="border p-2 mb-3">${job}: <span class="font-bold">${stats[job]}</span></div>`;
                    }
                    document.getElementById('stats_div').innerHTML = html;
                });
            },

            delete_jobs(type)
            {
                if(confirm('Are you sure you want to delete jobs?'))
                {
                    let scope = this;
                    axios.post('/admin/queue_monitoring/delete_jobs', {type: type})
                    .then(response => {
                        notify('success', 'All jobs deleted');
                        scope.$refs.iframe.contentWindow.location.reload();
                    });
                }
            },

        }

    }

}

if (document.URL.match(/profile/) || document.URL.match(/_view/))
{


    window.profile = function (emp_id, emp_type, photo_exists, text_editor_ele, url = null, tab = null)
    {

        if (!emp_id)
        {
            photo_exists = false;
        }
        if (!tab)
        {
            tab = '1';
        } else
        {
            if (tab == 'billing')
            {
                tab = '4';
                if (document.URL.match(/profile/))
                {
                    tab = '5';
                }
            }
        }

        let is_profile = document.URL.match(/profile/) ? true : false;


        return {
            show_cropper_modal: false,
            cropper: '',
            employee_photo_pond: '',
            has_photo: photo_exists,
            show_ssn: false,
            show_email_error_modal: false,
            show_add_credit_card_modal: false,
            url: url,
            active_tab: tab,
            show_add_card_error_div: false,
            show_confirm_delete_credit_card: false,
            show_add_notes: false,
            show_make_payment_modal: false,
            show_make_payment_error_div: false,
            show_credit_cards: false,
            show_create_invoice_modal: false,
            add_credit_modal: false,
            show_confirm_modal: false,
            confirm_button_text: '',
            show_auto_bill: false,

            init()
            {

                let scope = this;

                if (localStorage.getItem('show_details_saved') == 1)
                {
                    notify('success', 'Employee details successfully saved');
                    localStorage.setItem('show_details_saved', 0);
                }

                if (emp_id)
                {
                    let show_licenses = ['agent', 'mortgage'];
                    if (show_licenses.includes(emp_type))
                    {
                        if (document.querySelector('.licenses-div'))
                        {
                            scope.get_licenses();
                        }
                    }

                    if (!is_profile)
                    {
                        scope.docs();
                        scope.get_docs();
                        scope.get_notes();
                    }
                    scope.photo();

                    if (Array.isArray(text_editor_ele))
                    {
                        if (text_editor_ele.length > 0)
                        {
                            text_editor_ele.forEach(function (editor)
                            {
                                scope.init_text_editor(editor);
                            });
                        }
                    } else
                    {
                        scope.init_text_editor(text_editor_ele);
                    }
                    scope.show_profile_link();
                    scope.show_floify_link();

                    if (emp_type == 'mortgage')
                    {
                        if (document.getElementById('credit_cards_div'))
                        {
                            scope.get_credit_cards();
                        }
                        scope.get_transactions();
                        scope.get_transactions_declined();
                    }



                }
            },

            save_details(ele)
            {

                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                remove_form_errors();

                let scope = this;

                setTimeout(function ()
                {

                    let form = document.getElementById('employee_form');
                    let formData = new FormData(form);
                    formData.append('emp_id', emp_id);
                    formData.append('emp_type', emp_type);

                    axios.post('/employees/save_details', formData)
                        .then(function (response)
                        {
                            ele.innerHTML = button_html;
                            if (response.data)
                            {
                                if (response.data.success)
                                {
                                    notify('success', 'Employee details successfully saved');
                                    localStorage.setItem('show_details_saved', 1);
                                    location.reload();
                                } else if (response.data.error)
                                {
                                    scope.show_email_error_modal = true;
                                    scope.$refs.error_modal_text.innerHTML = response.data.error;
                                } else if (response.data.emp_id)
                                {
                                    emp_id = response.data.emp_id;
                                    window.location = '/employees/' + emp_type + '/' + emp_type + '_view/' + emp_id;
                                }
                            }
                        })
                        .catch(function (error)
                        {
                            if (error)
                            {
                                if (error.response)
                                {
                                    if (error.response.status == 422)
                                    {
                                        let errors = error.response.data.errors;
                                        show_form_errors(errors);
                                        ele.innerHTML = 'Save <i class="fal fa-check ml-2"></i>';
                                    }
                                }
                            }
                        });

                }, 500);
            },

            upload_pre_qual_template(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Uploading ... ');
                
                let formData = new FormData();
                let template = document.querySelector('#pre_qual_template').files[0];
                formData.append('template', template);
                
                axios.post('/employees/upload_pre_qual_template', formData)
                .then(function (response) {
                    ele.innerHTML = button_html;
                    notify('success', 'Pre-Qualification Template Successfully Uploaded');
                })
                .catch(function (error) {
                });
            },

            show_profile_link()
            {
                if (document.querySelector('#folder'))
                {
                    let folder = document.querySelector('#folder').value;
                    let link = this.url + '/' + folder;
                    document.querySelector('#folder_url').innerHTML = link;
                }
            },

            show_floify_link()
            {
                if (document.querySelector('#floify_folder'))
                {
                    let folder = document.querySelector('#floify_folder').value;
                    let link = 'https://' + folder + '.floify.com';
                    document.querySelector('#floify_folder_url').innerHTML = '<a href="' + link + '" target="_blank">' + link + '</a>';
                }
            },

            get_licenses()
            {
                axios.get('/employees/get_licenses', {
                    params: {
                        emp_id: emp_id,
                        emp_type: emp_type,
                    },
                })
                    .then(function (response)
                    {
                        document.querySelector('.licenses-div').innerHTML = response.data;
                    })
                    .catch(function (error)
                    {

                    });
            },

            add_license()
            {
                let html = document.querySelector('#license_template').innerHTML;
                document.querySelector('.licenses-div').insertAdjacentHTML('beforeend', html);
            },
            delete_license(ele)
            {
                ele.closest('.license').remove();
            },

            docs()
            {

                let scope = this;
                let employee_docs = document.getElementById('employee_docs');
                scope.employee_docs_pond = FilePond.create(employee_docs);

                scope.employee_docs_pond.setOptions({
                    allowImagePreview: false,
                    multiple: true,
                    server: {
                        process: {
                            url: '/employees/docs/docs_upload',
                            onerror: (response) => response.data,
                            ondata: (formData) =>
                            {
                                formData.append('emp_id', emp_id);
                                formData.append('emp_type', emp_type);
                                formData.append('_token', document.querySelector('[name="csrf-token"]').getAttribute('content'));
                                return formData;
                            }
                        }
                    },
                    labelIdle: 'Drag & Drop here or <span class="filepond--label-action"> Browse </span>',
                    onprocessfiles: () =>
                    {
                        scope.employee_docs_pond.removeFiles();
                        scope.get_docs();
                    }
                });
            },
            get_docs()
            {

                let scope = this;
                let formData = new FormData();
                formData.append('emp_id', emp_id);
                formData.append('emp_type', emp_type);
                formData.append('_token', document.querySelector('[name="csrf-token"]').getAttribute('content'));

                axios.post('/employees/docs/get_docs', formData, axios_options)
                    .then(function (response)
                    {
                        document.querySelector('.docs-div').innerHTML = '';
                        if (response.data.docs.length > 0)
                        {
                            response.data.docs.forEach(function (doc)
                            {
                                let html = document.querySelector('#doc_template').innerHTML;
                                html = html.replace(/%%id%%/g, doc.id);
                                html = html.replace(/%%file_name%%/g, doc.file_name);
                                html = html.replace(/%%url%%/g, doc.file_location_url);
                                document.querySelector('.docs-div').insertAdjacentHTML('beforeend', html);
                            });
                        }
                    })
                    .catch(function (error)
                    {

                    });

            },
            delete_doc(id)
            {
                if (confirm('Are you sure you want to delete this document?'))
                {
                    let scope = this;
                    let formData = new FormData();
                    formData.append('id', id);
                    axios.post('/employees/docs/delete_doc', formData)
                        .then(function (response)
                        {
                            scope.get_docs();
                            notify('success', 'Document deleted successfully.');
                        })
                        .catch(function (error)
                        {
                        });
                }
            },

            get_notes()
            {
                let scope = this;
                axios.get('/employees/get_notes', {
                    params: {
                        emp_id: emp_id,
                        emp_type: emp_type,
                    },
                })
                    .then(function (response)
                    {
                        scope.$refs.notes_div.innerHTML = response.data;
                    })
                    .catch(function (error)
                    {

                    });

            },

            add_notes(ele)
            {

                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                remove_form_errors();

                let scope = this;
                let form = document.querySelector('#add_notes_form');
                let formData = new FormData(form);
                formData.append('emp_id', emp_id);
                formData.append('emp_type', emp_type);

                axios.post('/employees/add_notes', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        scope.get_notes();
                        scope.show_add_notes = false;
                        notify('success', 'Note Saved');
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            delete_note(ele, id)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, '');
                remove_form_errors();

                let formData = new FormData();
                formData.append('id', id);

                axios.post('/employees/delete_note', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        scope.get_notes();
                        notify('success', 'Note Deleted');
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },

            photo()
            {

                let scope = this;
                let employee_photo = document.getElementById('employee_photo');
                scope.employee_photo_pond = FilePond.create(employee_photo);

                scope.employee_photo_pond.setOptions({
                    allowImagePreview: false,
                    server: {
                        process: {
                            url: '/filepond_upload',
                            method: 'POST',
                        }
                    },
                    labelIdle: 'Drag & Drop here or<br><span class="filepond--label-action"> Browse </span>',
                    onpreparefile: (file, output) =>
                    {
                        let img = new Image();
                        img.src = URL.createObjectURL(output);
                        img.id = 'crop_image';
                        let width = img.naturalWidth;
                        let height = img.naturalHeight;

                        document.querySelector('.crop-container').innerHTML = '';
                        document.querySelector('.crop-container').appendChild(img);
                        //scope.show_cropper(width, height);
                        scope.show_cropper_modal = true;

                        let image = document.querySelector('#crop_image');
                        scope.cropper = new Cropper(image, {
                            aspectRatio: 3 / 4,
                            minContainerHeight: height,
                            minContainerWidth: width,
                            minCanvasHeight: height,
                            minCanvasWidth: width,

                        });

                    },
                    onprocessfile: (file, output) =>
                    {
                    }
                });
            },
            show_cropper(width, height)
            {

                this.show_cropper_modal = true;

                let image = document.querySelector('#crop_image');
                this.cropper = new Cropper(image, {
                    aspectRatio: 3 / 4,
                    minContainerHeight: height,
                    minContainerWidth: width,
                    minCanvasHeight: height,
                    minCanvasWidth: width,

                });

            },
            save_cropped_image(ele)
            {
                let scope = this;

                ele.innerHTML = '<span class="spinner-border spinner-border-sm mr-2"></span> Saving Image...';

                scope.cropper.getCroppedCanvas({
                    width: 300,
                    height: 400,
                    fillColor: '#fff',
                    imageSmoothingEnabled: false,
                    imageSmoothingQuality: 'high',
                });

                // Upload cropped image to server if the browser supports `HTMLCanvasElement.toBlob`.
                // The default value for the second parameter of `toBlob` is 'image/png', change it if necessary.
                scope.cropper.getCroppedCanvas().toBlob((blob) =>
                {

                    let formData = new FormData();

                    // Pass the image file name as the third parameter if necessary.
                    formData.append('cropped_image', blob);
                    formData.append('emp_id', emp_id);
                    formData.append('emp_type', emp_type);
                    formData.append('_token', document.querySelector('[name="csrf-token"]').getAttribute('content'));

                    axios.post('/employees/photos/save_cropped_upload', formData)
                        .then(function (response)
                        {
                            if (response.data.error)
                            {
                                notify('error', response.data.error);
                            }
                            scope.cropper.destroy();
                            scope.show_cropper_modal = false;
                            document.getElementById('employee_image').setAttribute('src', response.data + '?t=' + Date.now())
                            scope.has_photo = true;
                            scope.employee_photo_pond.removeFiles();
                            ele.innerHTML = '<i class="fa-duotone fa-save mr-2"></i> Save';
                        })
                        .catch(function (error)
                        {

                        });

                }, 'image/png');

            },
            delete_photo()
            {

                if (confirm('Are you sure you want to delete this photo?'))
                {

                    let scope = this;
                    let formData = new FormData();
                    formData.append('emp_id', emp_id);
                    formData.append('emp_type', emp_type);

                    axios.post('/employees/photos/delete_photo', formData, axios_options)
                        .then(function (response)
                        {
                            document.getElementById('employee_image').setAttribute('src', '');
                            scope.has_photo = false;
                        })
                        .catch(function (error)
                        {

                        });

                }
            },
            init_text_editor(ele)
            {

                let options = {
                    selector: ele,
                    height: 500,
                    //width: 700,
                    menubar: 'tools edit format table',
                    statusbar: false,
                    plugins: 'image table code autoresize',
                    toolbar: 'image | undo redo | styleselect | bold italic | forecolor backcolor | align outdent indent |',
                    table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                    relative_urls: false,
                    document_base_url: location.hostname
                }
                text_editor(options);

            },
            save_bio(button)
            {

                let button_html = button.innerHTML;
                show_loading_button(button, 'Saving Bio...');
                let bio = tinyMCE.activeEditor.getContent();
                bio = '<div style="width: 100%">' + bio + '</div>';

                let formData = new FormData();
                formData.append('bio', bio);
                formData.append('emp_type', emp_type);
                formData.append('emp_id', emp_id);
                axios.post('/employees/profile/save_bio', formData)
                    .then(function (response)
                    {
                        notify('success', 'Your Bio has been saved successfully!');
                        button.innerHTML = button_html;
                    })
                    .catch(function (error)
                    {
                        if (error)
                        {
                            if (error.response.status == 422)
                            {
                                let errors = error.response.data.errors;
                                show_form_errors(errors);
                            }
                        }
                    });
            },
            save_signature(ele)
            {

                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving Signature...');
                let signature = tinyMCE.activeEditor.getContent();
                signature = '<div style="width: 100%">' + signature + '</div>';

                let formData = new FormData();
                formData.append('signature', signature);
                formData.append('emp_type', emp_type);
                formData.append('emp_id', emp_id);
                axios.post('/employees/profile/save_signature', formData)
                    .then(function (response)
                    {
                        notify('success', 'Your Signature has been saved successfully!');
                        ele.innerHTML = button_html;
                    })
                    .catch(function (error)
                    {
                        if (error)
                        {
                            if (error.response.status == 422)
                            {
                                let errors = error.response.data.errors;
                                show_form_errors(errors);
                            }
                        }
                    });
            },


            get_credit_cards(default_card = null)
            {
                let scope = this;
                axios.get('/employees/billing/get_credit_cards', {
                    params: {
                        emp_type: emp_type,
                        emp_id: emp_id,
                        default_card: default_card
                    },
                })
                    .then(function (response)
                    {
                        if (default_card)
                        {
                            default_card = response.data.credit_card;

                            let div = scope.$refs.default_card_used;
                            let html = `
                            <div class="item-center flex justify-start space-x-4 items-center">
                                <div>
                                    <i class="fab fa-cc-`+ default_card.issuer + ` fa-2x text-primary"></i>
                                </div>
                                <div>
                                    xxxx-`+ default_card.number + `
                                </div>
                                <div>
                                `+ default_card.name + `
                                </div>
                                <div>
                                `+ default_card.expire + `
                                </div>
                            </div>`;
                            div.innerHTML = html;

                            return;
                        }

                        document.getElementById('credit_cards_div').innerHTML = '';
                        document.getElementById('credit_cards_div').innerHTML += response.data;



                        setTimeout(function ()
                        {
                            let details_div = document.getElementById('default_card_details');
                            details_div.innerHTML = '';
                            if (document.getElementById('default_card_template'))
                            {
                                details_div.innerHTML = document.getElementById('default_card_template').innerHTML;
                                scope.show_auto_bill = true;
                            } else
                            {
                                details_div.innerHTML = 'No cards of file. Manage Payment Options to add a card. <i class="fa-light fa-circle-arrow-up text-green-700"></i>';
                                scope.show_auto_bill = false;
                            }
                        }, 500);
                    })
                    .catch(function (error)
                    {

                    });
            },
            add_credit_card(ele)
            {

                let scope = this;

                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                remove_form_errors();
                scope.show_add_card_error_div = false;

                let form = document.getElementById('add_credit_card_form');

                let formData = new FormData(form);
                formData.append('emp_id', emp_id);
                formData.append('emp_type', emp_type);

                axios.post('/employees/billing/add_credit_card', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        if (response.data.success)
                        {
                            scope.get_credit_cards();
                            scope.show_add_credit_card_modal = false;
                            ele.innerHTML = button_html;
                            form.reset();
                            notify('success', 'Credit Card successfully added');
                        } else
                        {
                            scope.show_add_card_error_div = true;
                            document.getElementById('add_card_error_message').innerText = response.data.error;
                        }
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },
            show_delete_credit_card(ele, profile_id, payment_profile_id)
            {

                let scope = this;

                let button = document.getElementById('confirm_delete_credit_card');
                button.setAttribute('data-profile-id', profile_id);
                button.setAttribute('data-payment-profile-id', payment_profile_id);
                button.setAttribute('data-ele', ele);

                scope.show_confirm_delete_credit_card = true;

            },
            delete_credit_card()
            {

                show_loading();

                let scope = this;
                let button = document.getElementById('confirm_delete_credit_card');
                let ele = button.getAttribute('data-ele');
                ele = document.getElementById(ele);
                let profile_id = button.getAttribute('data-profile-id');
                let payment_profile_id = button.getAttribute('data-payment-profile-id');

                ele.innerHTML = '<i class="fas fa-circle-notch fa-spin"></i>';

                let formData = new FormData();
                formData.append('profile_id', profile_id);
                formData.append('payment_profile_id', payment_profile_id);

                axios.post('/employees/billing/delete_credit_card', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = '<i class="fal fa-times"></i>';
                        if (response.data.success)
                        {
                            scope.get_credit_cards();
                            notify('success', 'Credit Card successfully deleted');
                            scope.show_confirm_delete_credit_card = false;
                            hide_loading();
                        } else if (response.data.error)
                        {

                        }
                    })
                    .catch(function (error)
                    {
                    });

            },
            set_default_credit_card(profile_id, payment_profile_id)
            {

                let scope = this;

                let formData = new FormData();
                formData.append('profile_id', profile_id);
                formData.append('payment_profile_id', payment_profile_id);

                axios.post('/employees/billing/set_default_credit_card', formData)
                    .then(function (response)
                    {
                        if (response.data.success)
                        {
                            scope.get_credit_cards();
                            notify('success', 'Default Credit Card Successfully Changed');
                        } else if (response.data.error)
                        {

                        }
                    })
                    .catch(function (error)
                    {
                    });
            },
            show_make_payment()
            {

                let scope = this;

                if (scope.$refs.no_credit_cards)
                {
                    alert('You must add a credit card before making a payment');
                    return false;
                }
                scope.show_add_card_error_div = false;
                scope.show_make_payment_modal = true;
                let amount = scope.$refs.balance.innerText.replace(/,/, '');
                if (amount && amount != '0.00')
                {
                    scope.$refs.payment_amount.value = amount;
                } else
                {
                    scope.$refs.payment_amount.value = '';

                }
                scope.get_credit_cards('default');
            },
            make_payment(ele)
            {

                let scope = this;

                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Submitting Payment ... ');
                remove_form_errors();
                scope.show_make_payment_error_div = false;

                let form = scope.$refs.make_payment_form;
                let formData = new FormData(form);
                formData.append('description', 'Heritage Financial Payment');

                axios.post('/employees/billing/make_payment', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        if (response.data.status == 'success')
                        {
                            scope.show_make_payment_modal = false;
                            scope.update_balance(response.data.balance);
                            notify('success', 'Payment Successfully Made');
                            scope.get_transactions();
                        } else
                        {
                            scope.show_make_payment_error_div = true;
                            document.getElementById('make_payment_error_message').innerText = response.data.error_text;
                            scope.get_transactions_declined();
                        }
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },
            add_credit(ele)
            {
                let scope = this;
                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Adding Credit ... ');
                remove_form_errors();

                let form = scope.$refs.add_credit_form;
                let formData = new FormData(form);

                axios.post('/employees/billing/add_credit', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Credit Successfully Added');
                        scope.get_transactions();
                        scope.update_balance(response.data.balance);
                        scope.add_credit_modal = false;
                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },
            create_invoice(ele)
            {
                let scope = this;
                let form = scope.$refs.create_invoice_form;

                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                remove_form_errors();

                let formData = new FormData(form);

                axios.post('/employees/billing/create_invoice', formData)
                    .then(function (response)
                    {
                        ele.innerHTML = button_html;
                        notify('success', 'Invoice Successfully Created');
                        scope.show_create_invoice_modal = false;
                        scope.get_transactions();
                        scope.update_balance(response.data.balance);

                    })
                    .catch(function (error)
                    {
                        display_errors(error, ele, button_html);
                    });
            },
            update_totals()
            {
                let scope = this;
                let item_rows = document.querySelectorAll('.item-row');
                let total = 0;
                item_rows.forEach(function (row)
                {
                    let quantity = row.querySelector('[name="quantity[]"]').value;
                    let amount = row.querySelector('[name="amount[]"]').value;
                    let total_amount = row.querySelector('[name="total_amount[]"]');
                    let item_total = (parseFloat(quantity) * parseFloat(amount)).toFixed(2);
                    if (!isNaN(item_total))
                    {
                        total_amount.value = item_total;
                    } else
                    {
                        total_amount.value = parseFloat('0.00');
                    }
                    total += parseFloat(total_amount.value);
                });
                document.getElementById('items_total_amount').innerText = 'Total - $' + total.toFixed(2);
                scope.$refs.invoice_amount.value = total.toFixed(2);
            },
            show_update_credit_card(id, user_id, profile_id, payment_profile_id)
            {
                let scope = this;

                axios.get('/employees/billing/get_credit_card_details', {
                    params: {
                        id: id
                    },
                })
                    .then(function (response)
                    {
                        scope.$refs.credit_card_id.value = id;
                        scope.$refs.credit_card_user_id.value = user_id;
                        scope.$refs.credit_card_profile_id.value = profile_id;
                        scope.$refs.credit_card_payment_profile_id.value = payment_profile_id;
                        let data = response.data[0];
                        for (const key in data)
                        {
                            if (document.querySelector('[name="' + key + '"]'))
                            {
                                document.querySelector('[name="' + key + '"]').value = data[key];
                            }
                        }
                    })
                    .catch(function (error)
                    {

                    });
                scope.show_add_credit_card_modal = true;
            },

            update_auto_bill(value)
            {
                let scope = this;
                value = value == true ? 1 : 0;
                let formData = new FormData();
                formData.append('emp_id', emp_id);
                formData.append('emp_type', emp_type);
                formData.append('auto_bill', value);

                axios.post('/employees/billing/update_auto_bill', formData)
                    .then(function (response)
                    {
                        notify('success', 'Auto Bill Updated');
                    })
                    .catch(function (error)
                    {
                    });
            },

            get_transactions()
            {
                let scope = this;
                axios.get('/employees/billing/get_transactions', {
                    params: {
                        emp_id: emp_id,
                        emp_type: emp_type,
                    },
                })
                    .then(function (response)
                    {
                        document.getElementById('transactions_div').innerHTML = response.data;
                    })
                    .catch(function (error)
                    {

                    });
            },
            get_transactions_declined()
            {
                let scope = this;
                axios.get('/employees/billing/get_transactions_declined', {
                    params: {
                        emp_id: emp_id,
                        emp_type: emp_type,
                    },
                })
                    .then(function (response)
                    {
                        document.getElementById('transactions_declined_div').innerHTML = response.data;
                    })
                    .catch(function (error)
                    {

                    });
            },

            confirm_void(id, transaction_id)
            {
                let scope = this;
                scope.$refs.confirm_id.value = id;
                scope.$refs.confirm_transaction_id.value = transaction_id;
                scope.confirm_modal('Are you sure you want to void this transaction?', 'void');
            },
            void_transaction(ele)
            {
                // determine if voidable
                let scope = this;
                let id = scope.$refs.confirm_id.value;
                let transaction_id = scope.$refs.confirm_transaction_id.value;

                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');

                axios.get('/employees/billing/void_transaction', {
                    params: {
                        id: id,
                        transaction_id: transaction_id
                    },
                })
                    .then(function (response)
                    {
                        if (response.data.error)
                        {
                            scope.hide_confirm_modal();
                            scope.show_email_error_modal = true;
                            scope.$refs.error_modal_text.innerHTML = '<span class="text-bold text-lg mb-2">Void Not Processed!</span><br>' + response.data.error;
                        } else if (response.data.refundable)
                        {
                            scope.$refs.confirm_id.value = id;
                            scope.$refs.confirm_transaction_id.value = transaction_id;
                            scope.confirm_modal('This transaction cannot be voided. Would you like to refund the charge?', 'refund');
                        } else
                        {
                            notify('success', 'Transaction Successfully Voided');
                            scope.hide_confirm_modal();
                            scope.get_transactions();
                            scope.update_balance(response.data.balance);
                        }
                        ele.innerHTML = button_html;
                    })
                    .catch(function (error)
                    {

                        ele.innerHTML = button_html;
                    });
            },
            confirm_refund(id, transaction_id)
            {
                let scope = this;
                scope.$refs.confirm_id.value = id;
                scope.$refs.confirm_transaction_id.value = transaction_id;
                scope.confirm_modal('Are you sure you want to refund this transaction?', 'refund');
            },
            refund_transaction(ele)
            {

                let scope = this;
                let id = scope.$refs.confirm_id.value;
                let transaction_id = scope.$refs.confirm_transaction_id.value;

                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');

                axios.get('/employees/billing/refund_transaction', {
                    params: {
                        id: id,
                        transaction_id: transaction_id
                    },
                })
                    .then(function (response)
                    {
                        if (response.data.status == 'error')
                        {
                            scope.hide_confirm_modal();
                            scope.show_email_error_modal = true;
                            scope.$refs.error_modal_text.innerHTML = '<span class="text-bold text-lg mb-2">Refund Not Processed!</span><br>' + response.data.error_text;
                        } else
                        {
                            notify('success', 'Transaction Successfully Refunded');
                            scope.hide_confirm_modal();
                            scope.get_transactions();
                            scope.update_balance(response.data.balance);
                        }
                        ele.innerHTML = button_html;
                    })
                    .catch(function (error)
                    {

                        ele.innerHTML = button_html;
                    });
            },

            confirm_delete_transaction(id)
            {
                let scope = this;
                scope.$refs.confirm_id.value = id;
                scope.confirm_modal('Are you sure you want to delete this invoice?', 'delete');
            },

            delete_transaction(ele)
            {
                let scope = this;
                let id = scope.$refs.confirm_id.value;

                let button_html = ele.innerHTML;
                show_loading_button(ele, 'Saving ... ');
                axios.get('/employees/billing/delete_transaction', {
                    params: {
                        id: id
                    },
                })
                    .then(function (response)
                    {
                        notify('success', 'Transaction Successfully Deleted');
                        scope.hide_confirm_modal();
                        scope.get_transactions();
                        scope.update_balance(response.data.balance);
                        ele.innerHTML = button_html;
                    })
                    .catch(function (error)
                    {

                    });

            },

            view_invoice(ele, transaction_id)
            {
                window.open('/employees/billing/view_invoice?transaction_id=' + transaction_id, '_blank');
                // let scope = this;
                // let button_html = ele.innerHTML;
                // show_loading_button(ele, 'Processing ... ');
                // axios.get('/employees/billing/view_invoice', {
                //     params: {
                //         transaction_id: transaction_id
                //     },
                // })
                //     .then(function (response)
                //     {
                //         window.open(response.data.url, '_blank');
                //         ele.innerHTML = button_html;
                //     })
                //     .catch(function (error)
                //     {

                //     });
            },

            email_invoice(transaction_id)
            {
                let scope = this;
                axios.get('/employees/billing/email_invoice', {
                    params: {
                        transaction_id: transaction_id
                    },
                })
                    .then(function (response)
                    {
                        notify('success', 'Invoice Successfully Emailed To Loan Officer');
                    })
                    .catch(function (error)
                    {

                    });
            },

            update_balance(amount)
            {

                let div = this.$refs.balance_main;
                this.$refs.balance.innerText = format_number(amount.toFixed(2));
                if (amount == '0.00' || amount < 0)
                {
                    div.classList.remove('text-red-800');
                    div.classList.add('text-green-800');
                } else
                {

                    div.classList.remove('text-green-800');
                    div.classList.add('text-red-800');
                }
            },

            confirm_modal(text, type)
            {
                let scope = this;
                scope.show_confirm_modal = true;
                scope.$refs.confirm_text.innerText = text;
                document.querySelectorAll('.confirm-button-div').forEach(function (div)
                {
                    div.classList.add('hidden');
                });
                document.querySelector('.' + type + '-button-div').classList.remove('hidden');
            },
            hide_confirm_modal()
            {
                this.show_confirm_modal = false;
            },


        }

    }

}
